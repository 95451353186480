<template> 
  <header class="header">
    <div class="container">
      <div class="row v-center">
        <div class="header-item item-left">
          <div class="logo">
			<router-link to="/"><img src="../assets/img/Logo.jpeg" alt=""></router-link>	
          </div>
        </div>
        <!-- menu start here -->
        <div class="header-item item-center">

          <nav class="menu">
            <div class="mobile-menu-head">
              <div class="go-back"><i class="fa fa-angle-left"></i></div>
              <div class="current-menu-title"></div>
              <div class="mobile-menu-close">&times;</div>
            </div>
            <ul class="menu-main">
              <li class="menu-item-has-children">
                <a href="#">{{ $t('menu.products') }}</a>
                <div class="sub-menu mega-menu mega-menu-column-4">
                  <div class="list-item">
                    <h4 class="title">{{ $t('menu.iotDevices') }}</h4>
                    <ul>
					<li><router-link to="/iot-devices/SEM">{{ $t('menu.sem') }}</router-link></li>
                    <li><router-link to="/iot-devices/PMU">{{ $t('menu.pmu') }}</router-link></li>
                    <li><router-link to="/iot-devices/DIO">{{ $t('menu.dio') }}</router-link></li>
					<li> <router-link to="/iot-devices/VGW">{{ $t('menu.vgw') }}</router-link></li>
                    </ul>
                  </div>

                  <div class="list-item">
                    <h4 class="title">{{ $t('menu.purchase') }}</h4>
                    <ul>
           				<li><router-link to="/contact"><a>{{ $t('menu.contact') }}</a></router-link></li>
                    </ul>
                  </div>
                </div>
              </li>
			  <li class="menu-item-has-children">
                <a href="#">{{ $t('menu.solutions') }}</a>
                <div class="sub-menu single-column-menu">
                  <ul>
                    <li><router-link to="/iot-devices/IoTDevices">{{ $t('menu.iotDevicesDesc') }} </router-link></li>
					<li><router-link to="">{{ $t('menu.energyManagement') }}</router-link></li>
                    <li><router-link to="">{{ $t('menu.networkSolutions') }}</router-link></li>
                  </ul>
                </div>
              </li>

              <li class="menu-item-has-children">
                <a href="#">{{ $t('menu.aboutUs') }}</a>
                <div class="sub-menu single-column-menu">
                  <ul>
                    <li><router-link to="/about">{{ $t('menu.missionVision') }} </router-link></li>
                    <li><router-link to="/blog">{{ $t('menu.blog') }}</router-link></li>
                  </ul>
                </div>
              </li>

			  <li class="menu-item-has-children">
                <a href="#">{{ $t('menu.resources') }}</a>
                <div class="sub-menu single-column-menu">
                  <ul>
                    <li><router-link to="/document">{{ $t('menu.documents') }}</router-link></li>
                  </ul>
                </div>
              </li>

			  <li>
                <router-link to="/career">{{ $t('menu.career') }}</router-link>
              </li>
              <li>
                <router-link to="/contact">{{ $t('menu.contactUs') }}</router-link>
              </li>

              <li>
                <select v-model="$i18n.locale" class="language">
                  <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale }}</option>
                </select>
              </li>
            </ul>
          </nav>
        </div>
        <!-- menu end here -->
        <div class="header-item item-right">
          <!-- mobile menu trigger -->
          <div class="mobile-menu-trigger">
            <span></span>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import router from '@/router';
 
export default{

    mounted: function () {
        const menu = document.querySelector(".menu");
        const menuMain = menu.querySelector(".menu-main");
        const goBack = menu.querySelector(".go-back");
        const menuTrigger = document.querySelector(".mobile-menu-trigger");
        const closeMenu = menu.querySelector(".mobile-menu-close");
        let subMenu;
        menuMain.addEventListener("click", (e) => {
            if (!menu.classList.contains("active")) {
                return;
            }
            if (e.target.closest(".menu-item-has-children")) {
                const hasChildren = e.target.closest(".menu-item-has-children");
                showSubMenu(hasChildren);
            }
        });
        goBack.addEventListener("click", () => {
            hideSubMenu();
        });
        menuTrigger.addEventListener("click", () => {
            toggleMenu();
        });
        closeMenu.addEventListener("click", () => {
            toggleMenu();
        });
        function toggleMenu() {
            menu.classList.toggle("active");
            //document.querySelector(".menu-overlay").classList.toggle("active");
        }
        function showSubMenu(hasChildren) {
            subMenu = hasChildren.querySelector(".sub-menu");
            subMenu.classList.add("active");
            subMenu.style.animation = "slideLeft 0.5s ease forwards";
            const menuTitle = hasChildren.querySelector("i").parentNode.childNodes[0].textContent;
            menu.querySelector(".current-menu-title").innerHTML = menuTitle;
            menu.querySelector(".mobile-menu-head").classList.add("active");
        }
        function hideSubMenu() {
            subMenu.style.animation = "slideRight 0.5s ease forwards";
            setTimeout(() => {
                subMenu.classList.remove("active");
            }, 300);
            menu.querySelector(".current-menu-title").innerHTML = "";
            menu.querySelector(".mobile-menu-head").classList.remove("active");
        }
        window.onresize = function () {
            if (this.innerWidth > 991) {
                if (menu.classList.contains("active")) {
                    toggleMenu();
                }
            }
        };
    },
    components: { router }
}
</script>
<style scoped>

header{
	z-index: 9999999999999;
}

*{
	background-color: #ffffff;
	margin:0;
    box-sizing: border-box; 
	padding: 0;
	font-size: 62,5%;
}
body{
	line-height: 1.5;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	overflow-x: hidden;
}
:before,:after{
	box-sizing: border-box;
}
.container{
	max-width: 100%;
}
.row{
	display: flex;
	flex-wrap: wrap;
}
.v-center{
	align-items: center;
}
ul{
	list-style: none;
	margin:0;
	padding:0;
}
a{
	text-decoration: none;
}
/* header */
.header{
	display: block;
	width: 100%;
	position: relative;
	z-index: 99;
	padding:10px;
	position: sticky;
	top: 0;
}
.header .item-left{
	flex:0 0 20%;
}
.header .logo img{
	width:40%;
}
.header .item-center{
	flex:0 0 60%;
}
.header .item-right{
	flex:0 0 17%;
	display: flex;
	justify-content: flex-end;
}
.header .item-right a{
    text-decoration: none;
    font-size: 15px;
    color:#555555;
    display: inline-block;
    margin-left: 20px;
    transition: color 0.3s ease;
}
.header .menu > ul > li{
	display: inline-block;
	line-height: 50px;
	margin-left: 30px;
}
.header .menu > ul > li > a{
	font-size: 13px;
	font-weight: bold;
	color:#000000;
	text-transform: capitalize;
	transition: color 0.3s ease;
	margin: auto;
}
.header .menu > ul > li .sub-menu{
	position: absolute;
	z-index: 500;
	background-color:#ffffff;
	box-shadow: -2px 2px 70px -25px rgba(0,0,0,0.3);
	padding: 20px 30px;
	transition: all 0.5s ease;
	margin-top:25px;
	opacity:0;
	visibility: hidden;
	
}
@media(max-width: 992px){
.header .logo{
	width:250px;
}
}
@media(min-width: 992px){


.menu-main{
	display: flex;
	justify-content: center;
	align-items: center;
	list-style: none;
}

.header .menu > ul > li.menu-item-has-children:hover .sub-menu{
	margin-top: 0;
	visibility: visible;
	opacity: 1;
}
}
.header .menu > ul > li .sub-menu > ul > li{
	line-height: 1;
}
.header .menu > ul > li .sub-menu > ul > li > a{
	display: inline-block;
	padding: 10px 0;
	font-size: 13px;
	color: #555555;
	transition: color 0.3s ease;
	text-decoration: none;
	text-transform: capitalize;
}
.header .menu > ul > li .single-column-menu{
	min-width: 280px;
	max-width: 350px;
	font-weight: bold;
}
.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li{
	line-height: 1;
	display: block;

}
.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a {
	padding:10px 0;
	display: inline-block;
	font-size: 13px;
	font-weight: bold;
	color:#555555;
	transition: color 0.3s ease;
	
}
.header .menu > ul > li .sub-menu.mega-menu{
    left: 50%;
    transform: translateX(-50%);
}

.header .menu > ul > li .sub-menu.mega-menu-column-4{
max-width: 1300px;
width: 100%;
display: flex;
flex-wrap: wrap;
padding:20px 15px;

}
.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item{
flex:0 0 25%;
padding:0 15px;
}
.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item .title{
	font-size: 16px;
	color:#ED2330;
	font-weight: bold;
	line-height: 1;
	padding:10px 0;
}
.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item.text-center .title{
	text-align: center;
	
}
.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item img{
	max-width: 100%;
	width: 100%;
	vertical-align: middle;
	margin-top: 10px;
}
.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a:hover,
.header .menu > ul > li .sub-menu > ul > li > a:hover,
.header .item-right a:hover,
.header .menu > ul > li:hover > a{
	color:#ED2330;
	text-decoration: none !important;
	cursor:pointer ;
}
.language{
	font-weight: bold;
	text-transform: uppercase;
	font-size: 13px;
}
@media(max-width:1350px){
	.main_banner{
	width: 100%;
    height: auto;
    padding: 100px 0;
	}
}

.mobile-menu-head,
.mobile-menu-trigger{
	display: none;
}

/*responsive*/
@media(max-width: 1000px){
	
	.language {
		margin-left: 15px;
		height: 50px;
	}

	.header .item-center{
		order:3;
		flex:0 0 100%;
	}
	.header .item-left,
	.header .item-right{
		flex:0 0 auto;
	}
	.v-center{
		justify-content: space-between;
	}
	.header .mobile-menu-trigger{
		display: flex;
		height: 30px;
		width: 30px;
		margin-left: 15px;
		cursor: pointer;
		align-items: center;
		justify-content: center;
	}
	.header .mobile-menu-trigger span{
		display: block;
		height: 2px;
		background-color: #333333;
		width: 24px;
		position: relative;
	}
	.header .mobile-menu-trigger span:before,
	.header .mobile-menu-trigger span:after{
		content: '';
		position: absolute;
		left:0;
		width: 100%;
		height: 100%;
		background-color: #333333;
	}
	.header .mobile-menu-trigger span:before{
		top:-6px;
	}
	.header .mobile-menu-trigger span:after{
		top:6px;
	}
	.header .item-right{
		align-items: center;
	}

	.header .menu{
		position: fixed;
		width: 360px;
		background-color:#ffffff;
		left:0;
		top:0;
		height: 100%;
		overflow: hidden;
		transform: translate(-100%);
		transition: all 0.5s ease;
		z-index: 1099;
	}
	.header .menu.active{
	transform: translate(0%);
	}
	.header .menu > ul > li{
		line-height: 1;
		margin:0;
		display: block;
	}
	.header .menu > ul > li > a{
		line-height: 50px;
		height: 50px;
		padding:0 50px 0 15px;
		display: block;
		border-bottom: 1px solid rgba(0,0,0,0.1);

	}
	.header .menu > ul > li > a i{
		position: absolute;
		height: 50px;
		width: 50px;
		top:0;
		right: 0;
		text-align: center;
		line-height: 50px;
		transform: rotate(-90deg);
	}
	.header .menu .mobile-menu-head{
		display: flex;
		height: 50px;
		border-bottom: 1px solid rgba(0,0,0,0.1);
		justify-content: space-between;
		align-items: center;
		position: relative;
		z-index: 501;
		position: sticky;
		background-color: #ffffff;
		top:0;
	}
	.header .menu .mobile-menu-head .go-back{
		height: 50px;
		width: 50px;
		border-right: 1px solid rgba(0,0,0,0.1);
		cursor: pointer;
		line-height: 50px;
		text-align: center;
		color:#000000;
		font-size: 16px;
		display: none;
	}
	.header .menu .mobile-menu-head.active .go-back{
		display: block;
	}
	.header .menu .mobile-menu-head .current-menu-title{
		font-size: 15px;
		font-weight: 500;
		color:#000000;
	}
	.header .menu .mobile-menu-head .mobile-menu-close{
	height: 50px;
	width: 50px;
	border-left: 1px solid rgba(0,0,0,0.1);
	cursor: pointer;
	line-height: 50px;
	text-align: center;
	color:#000000;
	font-size: 25px;
	}
	.header .menu .menu-main{
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
	}
	.header .menu > ul > li .sub-menu.mega-menu,
	.header .menu > ul > li .sub-menu{
		visibility: visible;
		opacity: 1;
		position: absolute;
		box-shadow: none;
		margin:0;
		padding:15px;
		font-weight: bold;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		padding-top: 65px;
		max-width: none;
		min-width: auto;
		display: none;
		transform: translateX(0%);
		overflow-y: auto;
	}
.header .menu > ul > li .sub-menu.active{
	display: block;
}
@keyframes slideLeft{
	0%{
		opacity:0;
		transform: translateX(100%);
	}
	100%{
	opacity:1;
	transform: translateX(0%);
	}
}
@keyframes slideRight{
	0%{
		opacity:1;
		transform: translateX(0%);
	}
	100%{
	opacity:0;
	transform: translateX(100%);
	}
}
	.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item img{
		margin-top:0;
	}
	.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item.text-center .title{
		margin-bottom: 20px;
		display: block;
	}
	.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item.text-center:last-child .title{
		margin-bottom:0px;
	}
	.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item{
		flex: 0 0 100%;
        padding: 0px;
	}
	.header .menu > ul > li .sub-menu > ul > li > a,
	.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a{
		display: block;
	}
	.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul {
		margin-bottom: 15px;
	}
	.menu-overlay{
		position: fixed;
		background-color: rgba(0,0,0,0.5);
		left:0;
		top:0;
		width: 100%;
		height: 100%;
		z-index: 1098;
		visibility: hidden;
		opacity:0;
		transition: all 0.5s ease;
	}
	.menu-overlay.active{
	visibility: visible;
	opacity:1;
	}
}

</style>